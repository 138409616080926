// based on react-app-polyfill/ie9

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable()
  window.Promise = require('promise/lib/es6-extensions.js')
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign')


const NUM_POLYFILLS = 10 // update this number if adding polyfills

let num = 0
const loaded = () => {
  num++
  if (num === NUM_POLYFILLS) {
    import('./web/index')
  }
}

if (window.fetch) {
  loaded()
} else {
  import('whatwg-fetch').then(loaded)
}

if (window.Intl) {
  loaded()
} else {
  import('intl').then(() => Promise.all([
    import('intl/locale-data/jsonp/en.js'),
    import('intl/locale-data/jsonp/fr.js'),
  ]))
  .then(loaded)
}

if (Array.prototype.find) {
  loaded()
} else {
  import('core-js/fn/array/find').then(loaded)
}

if (Array.prototype.includes) {
  loaded()
} else {
  import('core-js/fn/array/includes').then(loaded)
}

if (String.prototype.startsWith) {
  loaded()
} else {
  import('core-js/fn/string/starts-with').then(loaded)
}

if (String.prototype.endsWith) {
  loaded()
} else {
  import('core-js/fn/string/ends-with').then(loaded)
}

if (Number.isInteger) {
  loaded()
} else {
  import('core-js/fn/number/is-integer').then(loaded)
}

if (window.Map) {
  loaded()
} else {
  import('core-js/es6/map').then(loaded)
}

if (window.Set) {
  loaded()
} else {
  import('core-js/es6/set').then(loaded)
}

if (window.requestAnimationFrame) {
  loaded()
} else {
  import('raf').then((val) => {
    val.polyfill(window)
    loaded(true)
  })
}
